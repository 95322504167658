import { Component } from "@angular/core";
import { ScientificPublication } from "../../../interfaces/scientific-publication";
import { ToastrService } from "ngx-toastr";
import { MitpManagerService } from "../../../services/mitp-manager.service";
import { MatDialogClose, MatDialogRef } from "@angular/material/dialog";
import { MatDivider } from "@angular/material/divider";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel, MatSuffix } from "@angular/material/form-field";
import { NgIf } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatIcon } from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";
import { MatIconButton } from "@angular/material/button";
import { CdkDrag, CdkDragHandle } from "@angular/cdk/drag-drop";
import { JanusService } from "../../../services/janus.service";
import { finalize, first } from "rxjs";
import { NgxTolgeeModule } from "@tolgee/ngx";

@Component({
	selector: "app-janus",
	templateUrl: "./janus.component.html",
	styleUrls: ["./janus.component.scss"],
	imports: [
		CdkDrag,
		MatIconButton,
		CdkDragHandle,
		MatTooltip,
		MatIcon,
		MatDialogClose,
		MatCheckbox,
		ReactiveFormsModule,
		FormsModule,
		NgIf,
		MatFormField,
		MatLabel,
		MatInput,
		MatSuffix,
		MatProgressSpinner,
		CdkVirtualScrollViewport,
		CdkFixedSizeVirtualScroll,
		CdkVirtualForOf,
		MatMenuTrigger,
		MatMenu,
		MatMenuItem,
		MatDivider,
		NgxTolgeeModule,
	],
})
export class JanusComponent {
	isLoading = false;
	searchKeyword = "";
	publications: ScientificPublication[] = [];
	offset = 0;
	widthSize = "300px";
	advancedMode = true;
	aiAnswer = "";

	constructor(
		private toastr: ToastrService,
		private manager: MitpManagerService,
		private dialogRef: MatDialogRef<JanusComponent>,
		private janus: JanusService
	) {}

	search(): void {
		if (this.searchKeyword.trim() === "") {
			this.toastr.warning("Merci de renseigner des mots-clés pour la recherche.");
			return;
		}
		this.isLoading = true;
		if (this.advancedMode) {
			this.aiAnswer = "";
			this.janus
				.askQuestion(this.searchKeyword.trim())
				.pipe(
					first(),
					finalize(() => (this.isLoading = false))
				)
				.subscribe((response) => {
					console.log(response);
					this.aiAnswer = response["answer"];
					this.isLoading = false;
				});
		} else {
			this.publications = [];
			this.janus
				.searchArticles(encodeURIComponent(this.searchKeyword.trim()), this.offset)
				.pipe(
					first(),
					finalize(() => (this.isLoading = false))
				)
				.subscribe((articles) => {
					this.publications = articles;
					this.isLoading = false;
				});
		}
	}

	createResume(item: ScientificPublication): void {
		this.janus.getPublicationTldr(item.id).subscribe((result) => {
			if (result["resume"] === "") {
				this.toastr.error("Impossible de générer un résumé pour cette publication.");
			} else {
				this.manager.openInfoDialog(result["resume"]);
			}
		});
	}

	updateModalWidth(): void {
		if (this.widthSize === "300px") {
			this.widthSize = "600px";
		} else {
			this.widthSize = "300px";
		}
		this.dialogRef.updateSize(this.widthSize);
	}

	openUrl(item: ScientificPublication): void {
		this.janus.getPublicationUrl(item.doi).subscribe((result) => {
			window.open(result["url"], "_blank");
		});
	}

	showAbstract(item: ScientificPublication): void {
		if (item.abstract === "") {
			this.toastr.warning("Cette publication ne semble pas avoir un abstract.");
		} else {
			this.manager.openInfoDialog(item.abstract);
		}
	}
}
