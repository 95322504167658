import { Component, ViewChild } from "@angular/core";
import { VidalRecosService } from "./vidal-recos.service";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { finalize, first } from "rxjs";
import { VidalRecosResult } from "../../../../entities/vidal/vidal-recos-result";
import { MatDialogRef, MatDialogTitle, MatDialogClose, MatDialogContent } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortHeader } from "@angular/material/sort";
import {
	MatTableDataSource,
	MatTable,
	MatColumnDef,
	MatHeaderCellDef,
	MatHeaderCell,
	MatCellDef,
	MatCell,
	MatHeaderRowDef,
	MatHeaderRow,
	MatRowDef,
	MatRow,
} from "@angular/material/table";
import { MatTooltip } from "@angular/material/tooltip";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NgIf } from "@angular/common";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel, MatPrefix, MatSuffix } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatButton, MatIconButton } from "@angular/material/button";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";

@Component({
	selector: "app-vidal-recos",
	templateUrl: "./vidal-recos.component.html",
	styleUrls: ["./vidal-recos.component.scss"],
	imports: [
		MatDialogTitle,
		MatButton,
		MatIconButton,
		MatDialogClose,
		MatIcon,
		MatDialogContent,
		ReactiveFormsModule,
		MatFormField,
		MatLabel,
		MatInput,
		MatPrefix,
		NgIf,
		MatProgressSpinner,
		MatSuffix,
		MatTable,
		MatSort,
		MatColumnDef,
		MatHeaderCellDef,
		MatHeaderCell,
		MatSortHeader,
		MatCellDef,
		MatCell,
		MatTooltip,
		MatHeaderRowDef,
		MatHeaderRow,
		MatRowDef,
		MatRow,
		MatPaginator,
		NgxTolgeeModule,
	],
})
export class VidalRecosComponent {
	searchGroup: FormGroup;
	suggestions: string[] = [];
	isLoading = false;
	displayedColumns: string[] = ["title", "actions"];
	dataSource = new MatTableDataSource<VidalRecosResult>();
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	constructor(
		private vidalRecos: VidalRecosService,
		private toastr: ToastrService,
		private translate: TranslateService,
		formBuilder: FormBuilder,
		private dialogRef: MatDialogRef<VidalRecosComponent>
	) {
		this.searchGroup = formBuilder.group({
			searchKeyword: ["", []],
		});
		setTimeout(() => {
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});
	}

	getAll() {
		this.searchGroup.setValue({ searchKeyword: "" });
		this.isLoading = true;
		this.vidalRecos
			.getAll()
			.pipe(first())
			.pipe(finalize(() => (this.isLoading = false)))
			.subscribe({
				next: (response) => {
					this.dataSource.data = response;
				},
				error: () => {
					this.toastr.error(
						this.translate.instant("protocols.protocol.edit.toolbar.sources.vidal-recos.error")
					);
				},
			});
	}

	search(keyword: string) {
		this.isLoading = true;
		this.vidalRecos
			.search(keyword)
			.pipe(first())
			.pipe(finalize(() => (this.isLoading = false)))
			.subscribe({
				next: (response) => {
					this.dataSource.data = response;
				},
				error: () => {
					this.toastr.error(
						this.translate.instant("protocols.protocol.edit.toolbar.sources.vidal-recos.error")
					);
				},
			});
	}

	addReference(result: VidalRecosResult) {
		this.dialogRef.close(result);
	}

	openArticle(id: string) {
		this.vidalRecos
			.getById(id)
			.pipe(first())
			.subscribe({
				next: (response) => {
					const newTab = window.open("", "_blank");
					newTab.document.open();
					newTab.document.write(response);
					newTab.document.close();
				},
				error: () => {},
			});
	}
}
